/* This injects Tailwind's base styles and any base styles registered by plugins. */
@tailwind base;

/* This injects additional styles into Tailwind's base styles layer. */
@layer base {

    * {
        /* Text rendering */
        text-rendering: optimizeLegibility;
        -o-text-rendering: optimizeLegibility;
        -ms-text-rendering: optimizeLegibility;
        -moz-text-rendering: optimizeLegibility;
        -webkit-text-rendering: optimizeLegibility;
        -webkit-tap-highlight-color: transparent;

        /* Remove the focus ring */
        &:focus {
            outline: none !important;
        }
    }

    /* HTML and Body default styles */
    html,
    body {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        width: 100%;
        min-height: 100%;
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
    }

    /* Font size */
    html {
        font-size: 16px;
    }

    body {
        font-size: 0.875rem;
    }

    /* Stylistic alternates for Inter */
    body {
        font-feature-settings: 'salt';
    }

    /* Better spacing and border for horizontal rule */
    hr {
        margin: 32px 0;
        border-bottom-width: 1px;
    }

    /* Make images and videos to take up all the available space */
    img {
        width: 100%;
        vertical-align: top;
    }

    /* Fix: Disabled placeholder color is too faded on Safari */
    input[disabled] {
        opacity: 1;
        -webkit-text-fill-color: currentColor;
    }

    body, .dark, .light {
        @apply text-default bg-default #{'!important'};
    }

    *, *::before, *::after {
        --tw-border-opacity: 1 !important;
        border-color: rgba(var(--fuse-border-rgb), var(--tw-border-opacity));

        .dark & {
            --tw-border-opacity: 0.12 !important;
        }
    }

    [disabled] * {
        @apply text-disabled #{'!important'};
    }

    /* Print styles */
    @media print {

        /* Make the base font size smaller for print so everything is scaled nicely */
        html {
            font-size: 12px !important;
        }

        body, .dark, .light {
            background: none !important;
        }
    }

    ngx-daterangepicker-material .ranges{
        max-width: 100px;
    }

    .maximize {
        height: calc(100%) !important;
        width: calc(100%) !important;
        top: 0 !important;
        left: 0 !important;
        transform: translate3d(0, 0px, 0px) !important;
        z-index: 10 !important;
      }

      ng-dropdown-panel {
        .ng-dropdown-panel-items{
            width: 100% !important;
        }


    }

    .ng-select.auto-grow {
        display: flex;
      
        .ng-select-container {
          min-width: 100px;
          width: auto;
        }
      
        .ng-dropdown-panel {
          width: auto;
        }
      }
      
    .ng-value-container{
        overflow-y: auto;
        max-height: 60px;
    }

    date-selector{
        .mat-form-field-appearance-outline .mat-form-field-infix {
            padding: 0 !important;
            border-top: 0 !important;
            height: 40px;
            input{
                margin-top: 14px;
            }
        }
    }


    #sisenseApp {
        display: flex;
        flex: 1 1 auto;
        width: 100%;
        max-width: 100%;
        min-width: 0;
    }


      .mat-tooltip {
        white-space: pre-line;
      }

      .analytic_container{
        //height: calc(100% - 1.25rem);
        height: 100% !important;
      }

    //   .highcharts-container {
    //       height: 100% !important;
    //   }

    //   .highcharts-root{
    //     height: 100% !important;
    //   }

      .highlight {
        background-color: var(--fuse-primary-200) !important;
        font-weight: bold;
      }

      custom-slider .ngx-slider .ngx-slider-bar {
        background: var(--fuse-primary-100);
        height: 2px;
        top: 9px;
      }


      .custom-slider .ngx-slider .ngx-slider-selection {
        background: var(--fuse-primary-300)
      }

      .custom-slider .ngx-slider .ngx-slider-pointer:after {
        display: none;
      }

      .custom-slider .ngx-slider .ngx-slider-pointer {
          cursor: pointer;
          width: 15px;
          height: 15px;
          top: -5px;
          background-color:  var(--fuse-primary-300);
          z-index: 3;
          border-radius: 16px;
      }

      .custom-slider .ngx-slider .ngx-slider-limit {
        font-weight: bold;
        color: var(--fuse-primary-500);
      }

      .custom-slider .ngx-slider .ngx-slider-tick.ngx-slider-selected {
        background:  var(--fuse-primary-300);
      }



      .modules-checkbox mat-checkbox{
          display: block !important;
      }


      .cdk-drag-placeholder {
        opacity: 0;
      }

      .cdk-drag-animating {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }


       //JSON editor styling
    // div.jsoneditor,
    // div.jsoneditor-menu {
    //   border-color: #4b4b4b;
    // }
    // div.jsoneditor-menu {
    //   background-color: #4b4b4b;
    // }
    // div.jsoneditor-tree,
    // div.jsoneditor textarea.jsoneditor-text {
    //   background-color: #666666;
    //   color: #ffffff;
    // }
    // div.jsoneditor-field,
    // div.jsoneditor-value {
    //   color: #ffffff;
    // }
    // table.jsoneditor-search div.jsoneditor-frame {
    //   background: #808080;
    // }

    // tr.jsoneditor-highlight,
    // tr.jsoneditor-selected {
    //   background-color: #808080;
    // }

    // div.jsoneditor-field[contenteditable="true"]:focus,
    // div.jsoneditor-field[contenteditable="true"]:hover,
    // div.jsoneditor-value[contenteditable="true"]:focus,
    // div.jsoneditor-value[contenteditable="true"]:hover,
    // div.jsoneditor-field.jsoneditor-highlight,
    // div.jsoneditor-value.jsoneditor-highlight {
    //   background-color: #808080;
    //   border-color: #808080;
    // }

    // div.jsoneditor-field.highlight-active,
    // div.jsoneditor-field.highlight-active:focus,
    // div.jsoneditor-field.highlight-active:hover,
    // div.jsoneditor-value.highlight-active,
    // div.jsoneditor-value.highlight-active:focus,
    // div.jsoneditor-value.highlight-active:hover {
    //   background-color: #b1b1b1;
    //   border-color: #b1b1b1;
    // }

    // div.jsoneditor-tree button:focus {
    //   background-color: #868686;
    // }

    // /* coloring of JSON in tree mode */
    // div.jsoneditor-readonly {
    //   color: #acacac;
    // }
    // div.jsoneditor td.jsoneditor-separator {
    //   color: #acacac;
    // }
    // div.jsoneditor-value.jsoneditor-string {
    //   color: #00ff88;
    // }
    // div.jsoneditor-value.jsoneditor-object,
    // div.jsoneditor-value.jsoneditor-array {
    //   color: #bababa;
    // }
    // div.jsoneditor-value.jsoneditor-number {
    //   color: #ff4040;
    // }
    // div.jsoneditor-value.jsoneditor-boolean {
    //   color: #ff8048;
    // }
    // div.jsoneditor-value.jsoneditor-null {
    //   color: #49a7fc;
    // }
    // div.jsoneditor-value.jsoneditor-invalid {
    //   color: white;
    // }


    .md-drppicker{
        min-width: 600px;
        margin-top: 7px !important;
        z-index: 1001 !important;
    }

    .md-drppicker .ranges ul li button.active{
        background-color: var(--fuse-primary-600) !important;
    }

    .md-drppicker .btn{
        background-color: var(--fuse-primary-600) !important;
    }

    .md-drppicker td.active, .md-drppicker td.active:hover {
        background-color: var(--fuse-primary-600) !important;
    }
}
/* This injects Tailwind's component classes and any component classes registered by plugins. */
@tailwind components;
