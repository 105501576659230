/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

$primary:       #BED32B;
// $secondary:     $orange;
// $success:       $green;
// $info:          $lightblue;
// $warning:       $yellow;
// $danger:        $red;
// $light:         $gray-100;
// $dark:          $gray-800;

// @import "~jsoneditor/dist/jsoneditor.min.css";
// @import "~@ng-select/ng-select/themes/material.theme.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

// @import "~bootswatch/dist/pulse/_variables.scss";
@import "~bootstrap/scss/bootstrap.scss";
// $fa-font-path: '../../node_modules/font-awesome/fonts';
@import '~font-awesome/scss/font-awesome';
// @import "~bootswatch/dist/pulse/_bootswatch.scss";
